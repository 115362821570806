
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gdbx from '@/store/modules/gdbx';
import BaseFilterable from './BaseFilterable.vue';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { addComma, roundToFixed } from '../../../../util/number';
import { IndividualAgentCustomerSalesMetrics } from '@/store/models.def';

@Component({
	components: { BaseFilterable, DataInfo },
})
export default class IndividualAgentSalesMetricTableFilterable extends BaseFilterableContainer {
	@Prop({ default: () => ({}) })
	public year1Result!: IndividualAgentCustomerSalesMetrics;
	@Prop({ default: () => ({}) })
	public year2Result!: IndividualAgentCustomerSalesMetrics;
	@Prop({ default: () => ({}) })
	public year3Result!: IndividualAgentCustomerSalesMetrics;
	@Prop({ default: () => ({}) })
	public year4Result!: IndividualAgentCustomerSalesMetrics;

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get isContainDataInfoName() {
		return this.dataInfoName ? true : false;
	}

	// Year 1

	public get firstYear() {
		return this.year1Result.year;
	}
	public get year1SalesRanking() {
		return this.year1Result.salesRanking;
	}
	public get year1SalesMargin() {
		return this.year1Result.salesMargin;
	}
	public get year1RevenueContribution() {
		return this.year1Result.revenueContribution;
	}
	public get year1GrossProfitContribution() {
		return this.year1Result.grossProfitContribution;
	}
	public get year1CaseContribution() {
		return this.year1Result.caseContribution;
	}
	public get year1SalesAmount() {
		return addComma(this.year1Result.salesAmount, this.numDecimal);
	}
  public get year1SalesDifference() {
    return addComma(this.year1Result.salesAmount - this.year2Result.salesAmount, this.numDecimal);
  }
	public get year1SalesDocQty() {
		return this.year1Result.salesDocQty;
	}
  // public get year1AverageSalesPerCustomer() {
  //   return addComma((this.year1Result.salesAmount / this.year1Result))
  // }
	public get year1AverageSalesPerCase() {
		return addComma(this.year1Result.averageSalesPerCase, this.numDecimal);
	}
	public get year1AverageSalesCase() {
		return roundToFixed(this.year1Result.averageSalesCase, this.numDecimal);
	}
	public get year1AverageSales() {
		return addComma(this.year1Result.averageSales, this.numDecimal);
	}
	public get year1AverageSalesCycle() {
		return roundToFixed(this.year1Result.averageSalesCycle, this.numDecimal);
	}

	// Year 2

	public get secondYear() {
		return this.year2Result.year;
	}
	public get year2SalesRanking() {
		return this.year2Result.salesRanking;
	}
	public get year2SalesMargin() {
		return this.year2Result.salesMargin;
	}
	public get year2RevenueContribution() {
		return this.year2Result.revenueContribution;
	}
	public get year2GrossProfitContribution() {
		return this.year2Result.grossProfitContribution;
	}
	public get year2CaseContribution() {
		return this.year2Result.caseContribution;
	}
	public get year2SalesAmount() {
		return addComma(this.year2Result.salesAmount, this.numDecimal);
	}
  public get year2SalesDifference() {
    return addComma(this.year2Result.salesAmount - this.year3Result.salesAmount, this.numDecimal);
  }
	public get year2SalesDocQty() {
		return this.year2Result.salesDocQty;
	}
	public get year2AverageSalesPerCase() {
		return addComma(this.year2Result.averageSalesPerCase, this.numDecimal);
	}
	public get year2AverageSalesCase() {
		return roundToFixed(this.year2Result.averageSalesCase, this.numDecimal);
	}
	public get year2AverageSales() {
		return addComma(this.year2Result.averageSales, this.numDecimal);
	}
	public get year2AverageSalesCycle() {
		return roundToFixed(this.year2Result.averageSalesCycle, this.numDecimal);
	}

	// Year 3

	public get thirdYear() {
		return this.year3Result.year;
	}
	public get year3SalesRanking() {
		return this.year3Result.salesRanking;
	}
	public get year3SalesMargin() {
		return this.year3Result.salesMargin;
	}
	public get year3RevenueContribution() {
		return this.year3Result.revenueContribution;
	}
	public get year3GrossProfitContribution() {
		return this.year3Result.grossProfitContribution;
	}
	public get year3CaseContribution() {
		return this.year3Result.caseContribution;
	}
	public get year3SalesAmount() {
		return addComma(this.year3Result.salesAmount, this.numDecimal);
	}
  public get year3SalesDifference() {
    return addComma(this.year3Result.salesAmount - this.year4Result.salesAmount, this.numDecimal);
  }
	public get year3SalesDocQty() {
		return this.year3Result.salesDocQty;
	}
	public get year3AverageSalesPerCase() {
		return addComma(this.year3Result.averageSalesPerCase, this.numDecimal);
	}
	public get year3AverageSalesCase() {
		return roundToFixed(this.year3Result.averageSalesCase, this.numDecimal);
	}
	public get year3AverageSales() {
		return addComma(this.year3Result.averageSales, this.numDecimal);
	}
	public get year3AverageSalesCycle() {
		return roundToFixed(this.year3Result.averageSalesCycle, this.numDecimal);
	}

	// Year 4

	public get fourthYear() {
		return this.year4Result.year;
	}
	public get year4SalesRanking() {
		return this.year4Result.salesRanking;
	}
	public get year4SalesMargin() {
		return this.year4Result.salesMargin;
	}
	public get year4RevenueContribution() {
		return this.year4Result.revenueContribution;
	}
	public get year4GrossProfitContribution() {
		return this.year4Result.grossProfitContribution;
	}
	public get year4CaseContribution() {
		return this.year4Result.caseContribution;
	}
	public get year4SalesAmount() {
		return addComma(this.year4Result.salesAmount, this.numDecimal);
	}
	public get year4SalesDocQty() {
		return this.year4Result.salesDocQty;
	}
	public get year4AverageSalesPerCase() {
		return addComma(this.year4Result.averageSalesPerCase, this.numDecimal);
	}
	public get year4AverageSalesCase() {
		return roundToFixed(this.year4Result.averageSalesCase, this.numDecimal);
	}
	public get year4AverageSales() {
		return addComma(this.year4Result.averageSales, this.numDecimal);
	}
	public get year4AverageSalesCycle() {
		return roundToFixed(this.year4Result.averageSalesCycle, this.numDecimal);
	}
}
