
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup, SalesDetailCard } from '@/store/models.def';
import { roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		AmountDisplayerFilterable,
		IncludeCreditNoteToggle,
	},
})
export default class YTDRevenueContributionNumberDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public salesPercentage = '' || 0;

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get permissionIds(): PermissionsGroup[] {
		return ['customers', 'sales'];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get ytdRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('year').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const totalSalesRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.ytdRange)
			.includes('docType', ['IV', 'DN']);

		const totalCNRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.ytdRange)
			.includes('docType', ['CN']);

		let totalSalesAmount = await (await totalSalesRef.get()).getSum('amount');

		const totalCNAmount = await (await totalCNRef.get()).getSum('amount');

		totalSalesAmount -= totalCNAmount;

		const salesRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.ytdRange)
			.includes('docType', ['IV', 'DN']);

		const creditNoteRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateRange(this.ytdRange)
			.includes('docType', ['CN']);

		let agentSalesAmount = await (await salesRef.get()).getSum('amount');

		const agentCNAmount = await (await creditNoteRef.get()).getSum('amount');

		agentSalesAmount -= this.includeCreditNote ? agentCNAmount : 0;

		this.salesPercentage = roundToFixed(
			(agentSalesAmount / totalSalesAmount) * 100,
			this.decimal,
		);

		this.saveHistory('salesPercentage');
	}
}
