
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import IndividualAgentSalesMetricTableFilterable from '@/components/reports-v2/components/filterables/IndividualAgentSalesMetricTableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	PermissionsGroup,
	IndividualAgentCustomerSalesMetrics,
	DimensionMap,
	DimensionData,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import moment from 'moment';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { roundToFixed } from '@/util/number';

@Component({
	components: {
		IndividualAgentSalesMetricTableFilterable,
		DateFilter,
		SelectFilter,
	},
})
export default class IndividualAgentSalesMetricTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'quotations'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'compareDate1'
		| 'compareDate2'
	> = [];

	public year1Result = {} as IndividualAgentCustomerSalesMetrics;
	public year2Result = {} as IndividualAgentCustomerSalesMetrics;
	public year3Result = {} as IndividualAgentCustomerSalesMetrics;
	public year4Result = {} as IndividualAgentCustomerSalesMetrics;

	public selectedComparedYear: number = this.compareYearList[0];

	public get compareYearList() {
		const currentYear = moment().year();

		const list: number[] = [];

		for (let i = 1; i < 10; i++) {
			const year = currentYear - i;
			list.push(year);
		}

		return list;
	}

	public get ytdRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('year').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const { selectedAgents, selectedAsOfDate, selectedComparedYear, ytdRange } =
			this;
		return JSON.stringify([
			selectedAgents,
			selectedAsOfDate,
			selectedComparedYear,
			ytdRange,
		]);
	}

	public async expensiveCalc() {
		const mmt = moment(this.selectedAsOfDate);
		const currentMonth = mmt.month() + 1;
		const currentYear = mmt.year();

		// Year 1 ref

		const salesYear1Ref = FilteredDatabase.ref('sales')
			.year(this.selectedComparedYear)
			.agents(this.selectedAgents);

		const allSalesYear1Ref = FilteredDatabase.ref('sales').numberRange('date', [
			['>', moment().year(this.selectedComparedYear).startOf('year').valueOf()],
			['<=', moment().year(this.selectedComparedYear).endOf('year').valueOf()],
		]);

		const CNYear1Ref = FilteredDatabase.ref('creditNotes')
			.year(this.selectedComparedYear)
			.agents(this.selectedAgents);

		const allCNYear1Ref = FilteredDatabase.ref('creditNotes').numberRange(
			'date',
			[
				[
					'>',
					moment().year(this.selectedComparedYear).startOf('year').valueOf(),
				],
				[
					'<=',
					moment().year(this.selectedComparedYear).endOf('year').valueOf(),
				],
			],
		);

		const tradeIvdnYear1Ref = FilteredDatabase.ref('customerTrades')
			.year(this.selectedComparedYear)
			.agents(this.selectedAgents)
			.includes('docType', ['IV', 'DN']);

		const tradeCNYear1Ref = FilteredDatabase.ref('customerTrades')
			.year(this.selectedComparedYear)
			.agents(this.selectedAgents)
			.includes('docType', ['CN']);

		const allTradeIvdnYear1Ref = FilteredDatabase.ref('customerTrades')
			.numberRange('date', [
				[
					'>',
					moment().year(this.selectedComparedYear).startOf('year').valueOf(),
				],
				[
					'<=',
					moment().year(this.selectedComparedYear).endOf('year').valueOf(),
				],
			])
			.includes('docType', ['IV', 'DN']);

		const allTradeCNYear1Ref = FilteredDatabase.ref('customerTrades')
			.numberRange('date', [
				[
					'>',
					moment().year(this.selectedComparedYear).startOf('year').valueOf(),
				],
				[
					'<=',
					moment().year(this.selectedComparedYear).endOf('year').valueOf(),
				],
			])
			.includes('docType', ['CN']);

		// Year 2 ref

		const salesYear2Ref = FilteredDatabase.ref('sales')
			.year(this.selectedComparedYear - 2)
			.agents(this.selectedAgents);

		const allSalesYear2Ref = FilteredDatabase.ref('sales').numberRange('date', [
			[
				'>',
				moment()
					.year(this.selectedComparedYear - 1)
					.startOf('year')
					.valueOf(),
			],
			[
				'<=',
				moment()
					.year(this.selectedComparedYear - 1)
					.endOf('year')
					.valueOf(),
			],
		]);

		const CNYear2Ref = FilteredDatabase.ref('creditNotes')
			.year(this.selectedComparedYear - 1)
			.agents(this.selectedAgents);

		const allCNYear2Ref = FilteredDatabase.ref('creditNotes').numberRange(
			'date',
			[
				[
					'>',
					moment()
						.year(this.selectedComparedYear - 1)
						.startOf('year')
						.valueOf(),
				],
				[
					'<=',
					moment()
						.year(this.selectedComparedYear - 1)
						.endOf('year')
						.valueOf(),
				],
			],
		);

		const tradeIvdnYear2Ref = FilteredDatabase.ref('customerTrades')
			.year(this.selectedComparedYear - 1)
			.agents(this.selectedAgents)
			.includes('docType', ['IV', 'DN']);

		const tradeCNYear2Ref = FilteredDatabase.ref('customerTrades')
			.year(this.selectedComparedYear - 1)
			.agents(this.selectedAgents)
			.includes('docType', ['CN']);

		const allTradeIvdnYear2Ref = FilteredDatabase.ref('customerTrades')
			.numberRange('date', [
				[
					'>',
					moment()
						.year(this.selectedComparedYear - 1)
						.startOf('year')
						.valueOf(),
				],
				[
					'<=',
					moment()
						.year(this.selectedComparedYear - 1)
						.endOf('year')
						.valueOf(),
				],
			])
			.includes('docType', ['IV', 'DN']);

		const allTradeCNYear2Ref = FilteredDatabase.ref('customerTrades')
			.numberRange('date', [
				[
					'>',
					moment()
						.year(this.selectedComparedYear - 1)
						.startOf('year')
						.valueOf(),
				],
				[
					'<=',
					moment()
						.year(this.selectedComparedYear - 1)
						.endOf('year')
						.valueOf(),
				],
			])
			.includes('docType', ['CN']);

		// Year 3 ref

		const salesYear3Ref = FilteredDatabase.ref('sales')
			.year(this.selectedComparedYear - 2)
			.agents(this.selectedAgents);

		const allSalesYear3Ref = FilteredDatabase.ref('sales').numberRange('date', [
			[
				'>',
				moment()
					.year(this.selectedComparedYear - 2)
					.startOf('year')
					.valueOf(),
			],
			[
				'<=',
				moment()
					.year(this.selectedComparedYear - 1)
					.endOf('year')
					.valueOf(),
			],
		]);

		const CNYear3Ref = FilteredDatabase.ref('creditNotes')
			.year(this.selectedComparedYear - 2)
			.agents(this.selectedAgents);

		const allCNYear3Ref = FilteredDatabase.ref('creditNotes').numberRange(
			'date',
			[
				[
					'>',
					moment()
						.year(this.selectedComparedYear - 2)
						.startOf('year')
						.valueOf(),
				],
				[
					'<=',
					moment()
						.year(this.selectedComparedYear - 2)
						.endOf('year')
						.valueOf(),
				],
			],
		);

		const tradeIvdnYear3Ref = FilteredDatabase.ref('customerTrades')
			.year(this.selectedComparedYear - 2)
			.agents(this.selectedAgents)
			.includes('docType', ['IV', 'DN']);

		const tradeCNYear3Ref = FilteredDatabase.ref('customerTrades')
			.year(this.selectedComparedYear - 2)
			.agents(this.selectedAgents)
			.includes('docType', ['CN']);

		const allTradeIvdnYear3Ref = FilteredDatabase.ref('customerTrades')
			.numberRange('date', [
				[
					'>',
					moment()
						.year(this.selectedComparedYear - 2)
						.startOf('year')
						.valueOf(),
				],
				[
					'<=',
					moment()
						.year(this.selectedComparedYear - 2)
						.endOf('year')
						.valueOf(),
				],
			])
			.includes('docType', ['IV', 'DN']);

		const allTradeCNYear3Ref = FilteredDatabase.ref('customerTrades')
			.numberRange('date', [
				[
					'>',
					moment()
						.year(this.selectedComparedYear - 2)
						.startOf('year')
						.valueOf(),
				],
				[
					'<=',
					moment()
						.year(this.selectedComparedYear - 2)
						.endOf('year')
						.valueOf(),
				],
			])
			.includes('docType', ['CN']);

		// Current year ref

		const salesCurrentYearRef = FilteredDatabase.ref('sales')
      .year(this.selectedComparedYear - 3)
			.agents(this.selectedAgents);

		const allSalesCurrentYearRef = FilteredDatabase.ref('sales').numberRange('date', [
      [
        '>',
        moment()
            .year(this.selectedComparedYear - 3)
            .startOf('year')
            .valueOf(),
      ],
      [
        '<=',
        moment()
            .year(this.selectedComparedYear - 3)
            .endOf('year')
            .valueOf(),
      ],
    ]);

		const CNCurrentYearRef = FilteredDatabase.ref('creditNotes')
      .year(this.selectedComparedYear - 3)
			.agents(this.selectedAgents);

		const allCNCurrentYearRef = FilteredDatabase.ref('creditNotes').numberRange(
        'date',
        [
          [
            '>',
            moment()
                .year(this.selectedComparedYear - 1)
                .startOf('year')
                .valueOf(),
          ],
          [
            '<=',
            moment()
                .year(this.selectedComparedYear - 1)
                .endOf('year')
                .valueOf(),
          ],
        ],
    );

		const tradeIvdnCurrentYearRef = FilteredDatabase.ref('customerTrades')
      .year(this.selectedComparedYear - 3)
			.agents(this.selectedAgents)
			.includes('docType', ['IV', 'DN']);

		const tradeCNCurrentYearRef = FilteredDatabase.ref('customerTrades')
      .year(this.selectedComparedYear - 3)
			.agents(this.selectedAgents)
			.includes('docType', ['CN']);

		const allTradeIvdnCurrentYearRef = FilteredDatabase.ref('customerTrades')
        .numberRange('date', [
          [
            '>',
            moment()
                .year(this.selectedComparedYear - 3)
                .startOf('year')
                .valueOf(),
          ],
          [
            '<=',
            moment()
                .year(this.selectedComparedYear - 3)
                .endOf('year')
                .valueOf(),
          ],
        ])
			.includes('docType', ['IV', 'DN']);

		const allTradeCNCurrentYearRef = FilteredDatabase.ref('customerTrades')
        .numberRange('date', [
          [
            '>',
            moment()
                .year(this.selectedComparedYear - 3)
                .startOf('year')
                .valueOf(),
          ],
          [
            '<=',
            moment()
                .year(this.selectedComparedYear - 3)
                .endOf('year')
                .valueOf(),
          ],
        ])
			.includes('docType', ['CN']);

		// ********
		// Ranking
		// ********

		let pa = 0.25;
		let pb = 0;

		const allSalesYear1DD: DimensionData[] = await this._loadDimensionByFilters(
			allSalesYear1Ref,
			'agents',
			pa,
			pb,
			'amount',
		);

		const sortedAllSalesYear1DD = allSalesYear1DD.sort((a, b) => b.sum - a.sum);

		const year1SalesRanking =
			sortedAllSalesYear1DD.findIndex(
				(dd) => dd.value[0] === this.selectedAgents[0],
			) + 1;

		pb += pa;
		pa = 0.25;

		const allSalesYear2DD: DimensionData[] = await this._loadDimensionByFilters(
			allSalesYear2Ref,
			'agents',
			pa,
			pb,
			'amount',
		);

		const sortedAllSalesYear2DD = allSalesYear2DD.sort((a, b) => b.sum - a.sum);

		const year2SalesRanking =
			sortedAllSalesYear2DD.findIndex(
				(dd) => dd.value[0] === this.selectedAgents[0],
			) + 1;

		pb += pa;
		pa = 0.25;

		const allSalesYear3DD: DimensionData[] = await this._loadDimensionByFilters(
			allSalesYear3Ref,
			'agents',
			pa,
			pb,
			'amount',
		);

		const sortedAllSalesYear3DD = allSalesYear3DD.sort((a, b) => b.sum - a.sum);

		const year3SalesRanking =
			sortedAllSalesYear3DD.findIndex(
				(dd) => dd.value[0] === this.selectedAgents[0],
			) + 1;

		pb += pa;
		pa = 0.25;

		const allSalesCurrentYearDD: DimensionData[] =
			await this._loadDimensionByFilters(
				allSalesCurrentYearRef,
				'agents',
				pa,
				pb,
				'amount',
			);

		this.generateLoadingText(1);

		const sortedAllSalesCurrentYearDD = allSalesCurrentYearDD.sort(
			(a, b) => b.sum - a.sum,
		);

		const currentYearSalesRanking =
			sortedAllSalesCurrentYearDD.findIndex(
				(dd) => dd.value[0] === this.selectedAgents[0],
			) + 1;

		const salesYear1RefHead = await salesYear1Ref.get();
		const salesYear2RefHead = await salesYear2Ref.get();
		const salesYear3RefHead = await salesYear3Ref.get();
		const salesCurrentYearRefHead = await salesCurrentYearRef.get();

		const CNYear1RefHead = await CNYear1Ref.get();
		const CNYear2RefHead = await CNYear2Ref.get();
		const CNYear3RefHead = await CNYear3Ref.get();
		const CNCurrentYearRefHead = await CNCurrentYearRef.get();

		const tradeIvdnYear1RefHead = await tradeIvdnYear1Ref.get();
		const tradeIvdnYear2RefHead = await tradeIvdnYear2Ref.get();
		const tradeIvdnYear3RefHead = await tradeIvdnYear3Ref.get();
		const tradeIvdnCurrentYearRefHead = await tradeIvdnCurrentYearRef.get();

		const tradeCNYear1RefHead = await tradeCNYear1Ref.get();
		const tradeCNYear2RefHead = await tradeCNYear2Ref.get();
		const tradeCNYear3RefHead = await tradeCNYear3Ref.get();
		const tradeCNCurrentYearRefHead = await tradeCNCurrentYearRef.get();

		const allSalesYear1RefHead = await allSalesYear1Ref.get();
		const allSalesYear2RefHead = await allSalesYear2Ref.get();
		const allSalesYear3RefHead = await allSalesYear3Ref.get();
		const allSalesCurrentYearRefHead = await allSalesCurrentYearRef.get();

		const allCNYear1RefHead = await allCNYear1Ref.get();
		const allCNYear2RefHead = await allCNYear2Ref.get();
		const allCNYear3RefHead = await allCNYear3Ref.get();
		const allCNCurrentYearRefHead = await allCNCurrentYearRef.get();

		const allTradeIvdnYear1RefHead = await allTradeIvdnYear1Ref.get();
		const allTradeIvdnYear2RefHead = await allTradeIvdnYear2Ref.get();
		const allTradeIvdnYear3RefHead = await allTradeIvdnYear3Ref.get();
		const allTradeIvdnCurrentYearRefHead =
			await allTradeIvdnCurrentYearRef.get();

		const allTradeCNYear1RefHead = await allTradeCNYear1Ref.get();
		const allTradeCNYear2RefHead = await allTradeCNYear2Ref.get();
		const allTradeCNYear3RefHead = await allTradeCNYear3Ref.get();
		const allTradeCNCurrentYearRefHead = await allTradeCNCurrentYearRef.get();

		const year2 = moment().year(this.selectedComparedYear).add(-1, 'year').get('year');
		const year3 = moment().year(this.selectedComparedYear).add(-2, 'year').get('year');
  const year4 = moment().year(this.selectedComparedYear).add(-3, 'year').get('year');

		// ********
		// Sales
		// ********

		const year1SalesDocQty = (await salesYear1RefHead.getSet('docNo')).size;
		const year2SalesDocQty = (await salesYear2RefHead.getSet('docNo')).size;
		const year3SalesDocQty = (await salesYear3RefHead.getSet('docNo')).size;
		const currentYearSalesDocQty = (
			await salesCurrentYearRefHead.getSet('docNo')
		).size;

		const year1AllSalesDocQty = (await allSalesYear1RefHead.getSet('docNo'))
			.size;
		const year2AllSalesDocQty = (await allSalesYear2RefHead.getSet('docNo'))
			.size;
		const year3AllSalesDocQty = (await allSalesYear3RefHead.getSet('docNo'))
			.size;
		const currentYearAllSalesDocQty = (
			await allSalesCurrentYearRefHead.getSet('docNo')
		).size;

		const year1SalesDocDateQty = (await salesYear1RefHead.getSet('date')).size;
		const year2SalesDocDateQty = (await salesYear2RefHead.getSet('date')).size;
		const year3SalesDocDateQty = (await salesYear3RefHead.getSet('date')).size;
		const currentYearSalesDocDateQty = (
			await salesCurrentYearRefHead.getSet('date')
		).size;

		// All sales Amount

		const year1AllSalesAmount =
			(await allTradeIvdnYear1RefHead.getSum('amount')) -
			(await allTradeCNYear1RefHead.getSum('amount'));
		const year2AllSalesAmount =
			(await allTradeIvdnYear2RefHead.getSum('amount')) -
			(await allTradeCNYear2RefHead.getSum('amount'));
		const year3AllSalesAmount =
			(await allTradeIvdnYear3RefHead.getSum('amount')) -
			(await allTradeCNYear3RefHead.getSum('amount'));
		const currentYearAllSalesAmount =
			(await allTradeIvdnCurrentYearRefHead.getSum('amount')) -
			(await allTradeCNCurrentYearRefHead.getSum('amount'));

		// Sales Amount

		const year1SalesAmount =
			(await tradeIvdnYear1RefHead.getSum('amount')) -
			(await tradeCNYear1RefHead.getSum('amount'));
		const year2SalesAmount =
			(await tradeIvdnYear2RefHead.getSum('amount')) -
			(await tradeCNYear2RefHead.getSum('amount'));
		const year3SalesAmount =
			(await tradeIvdnYear3RefHead.getSum('amount')) -
			(await tradeCNYear3RefHead.getSum('amount'));
		const currentYearSalesAmount =
			(await tradeIvdnCurrentYearRefHead.getSum('amount')) -
			(await tradeCNCurrentYearRefHead.getSum('amount'));

		// All Sales Profit

		const year1AllSalesProfit =
			(await allSalesYear1RefHead.getSum('profit')) -
			(await allCNYear1RefHead.getSum('profit'));
		const year2AllSalesProfit =
			(await allSalesYear2RefHead.getSum('profit')) -
			(await allCNYear2RefHead.getSum('profit'));
		const year3AllSalesProfit =
			(await allSalesYear3RefHead.getSum('profit')) -
			(await allCNYear3RefHead.getSum('profit'));
		const currentYearAllSalesProfit =
			(await allSalesCurrentYearRefHead.getSum('profit')) -
			(await allCNCurrentYearRefHead.getSum('profit'));

		// Sales Profit

		const year1SalesProfit =
			(await salesYear1RefHead.getSum('profit')) -
			(await CNYear1RefHead.getSum('profit'));
		const year2SalesProfit =
			(await salesYear2RefHead.getSum('profit')) -
			(await CNYear2RefHead.getSum('profit'));
		const year3SalesProfit =
			(await salesYear3RefHead.getSum('profit')) -
			(await CNYear3RefHead.getSum('profit'));
		const currentYearSalesProfit =
			(await salesCurrentYearRefHead.getSum('profit')) -
			(await CNCurrentYearRefHead.getSum('profit'));

		this.generateLoadingText(1);

		// Sales Margin

		const year1SalesMargin =
			year1SalesAmount !== 0
				? roundToFixed(
						(year1SalesProfit / year1SalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;
		const year2SalesMargin =
			year2SalesAmount !== 0
				? roundToFixed(
						(year2SalesProfit / year2SalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;
		const year3SalesMargin =
			year3SalesAmount !== 0
				? roundToFixed(
						(year3SalesProfit / year3SalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;
		const currentYearSalesMargin =
			currentYearSalesAmount !== 0
				? roundToFixed(
						(currentYearSalesProfit / currentYearSalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;

		// Revenue Contribution

		const year1RevenueContribution =
			year1AllSalesAmount !== 0
				? roundToFixed(
						(year1SalesAmount / year1AllSalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;
		const year2RevenueContribution =
			year2AllSalesAmount !== 0
				? roundToFixed(
						(year2SalesAmount / year2AllSalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;
		const year3RevenueContribution =
			year3AllSalesAmount !== 0
				? roundToFixed(
						(year3SalesAmount / year3AllSalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;
		const currentYearRevenueContribution =
			currentYearAllSalesAmount !== 0
				? roundToFixed(
						(currentYearSalesAmount / currentYearAllSalesAmount) * 100,
						this.numDecimal,
				  )
				: 0;

		// Gross Profit Contribution

		const year1GrossProfitContribution =
			year1AllSalesProfit !== 0
				? roundToFixed(
						(year1SalesProfit / year1AllSalesProfit) * 100,
						this.numDecimal,
				  )
				: 0;
		const year2GrossProfitContribution =
			year2AllSalesProfit !== 0
				? roundToFixed(
						(year2SalesProfit / year2AllSalesProfit) * 100,
						this.numDecimal,
				  )
				: 0;
		const year3GrossProfitContribution =
			year3AllSalesProfit !== 0
				? roundToFixed(
						(year3SalesProfit / year3AllSalesProfit) * 100,
						this.numDecimal,
				  )
				: 0;
		const currentYearGrossProfitContribution =
			currentYearAllSalesProfit !== 0
				? roundToFixed(
						(currentYearSalesProfit / currentYearAllSalesProfit) * 100,
						this.numDecimal,
				  )
				: 0;

		// Case Contribution

		const year1CaseContribution =
			year1AllSalesDocQty !== 0
				? roundToFixed(
						(year1SalesDocQty / year1AllSalesDocQty) * 100,
						this.numDecimal,
				  )
				: 0;
		const year2CaseContribution =
			year2AllSalesDocQty !== 0
				? roundToFixed(
						(year2SalesDocQty / year2AllSalesDocQty) * 100,
						this.numDecimal,
				  )
				: 0;
		const year3CaseContribution =
			year3AllSalesDocQty !== 0
				? roundToFixed(
						(year3SalesDocQty / year3AllSalesDocQty) * 100,
						this.numDecimal,
				  )
				: 0;
		const currentYearCaseContribution =
			currentYearAllSalesDocQty !== 0
				? roundToFixed(
						(currentYearSalesDocQty / currentYearAllSalesDocQty) * 100,
						this.numDecimal,
				  )
				: 0;

		// Averegae Sales Per Case

		const year1AverageSalesPerCase =
			year1SalesDocQty !== 0 ? year1SalesAmount / year1SalesDocQty : 0;
		const year2AverageSalesPerCase =
			year2SalesDocQty !== 0 ? year2SalesAmount / year2SalesDocQty : 0;
		const year3AverageSalesPerCase =
			year3SalesDocQty !== 0 ? year3SalesAmount / year3SalesDocQty : 0;
		const currentYearAverageSalesPerCase =
			currentYearSalesDocQty !== 0
				? currentYearSalesAmount / currentYearSalesDocQty
				: 0;

		// Average Sales Case

		const year1AverageSalesCase =
			this.selectedComparedYear === currentYear
				? year1SalesDocQty / currentMonth
				: year1SalesDocQty / 12;
		const year2AverageSalesCase =
			year2 === currentYear
				? year2SalesDocQty / currentMonth
				: year2SalesDocQty / 12;
		const year3AverageSalesCase =
			year3 === currentYear
				? year3SalesDocQty / currentMonth
				: year3SalesDocQty / 12;
		const currentYearAverageSalesCase = currentYearSalesDocQty / currentMonth;

		// Average Sales

		const year1AverageSales =
			this.selectedComparedYear === currentYear
				? year1SalesAmount / currentMonth
				: year1SalesAmount / 12;
		const year2AverageSales =
			year2 === currentYear
				? year2SalesAmount / currentMonth
				: year2SalesAmount / 12;
		const year3AverageSales =
			year3 === currentYear
				? year3SalesAmount / currentMonth
				: year3SalesAmount / 12;
		const currentYearAverageSales = currentYearSalesAmount / currentMonth;

		// Average Sales Cycle

		const year1AverageSalesCycle =
			year1SalesDocDateQty !== 0
				? moment().endOf('year').year(this.selectedComparedYear).dayOfYear() /
				  year1SalesDocDateQty
				: 0;
		const year2AverageSalesCycle =
			year2SalesDocDateQty !== 0
				? moment().endOf('year').year(year2).dayOfYear() / year2SalesDocDateQty
				: 0;
		const year3AverageSalesCycle =
			year3SalesDocDateQty !== 0
				? moment().endOf('year').year(year3).dayOfYear() / year3SalesDocDateQty
				: 0;
		const currentYearAverageSalesCycle =
			currentYearSalesDocDateQty !== 0
				? moment(this.ytdRange[1]).diff(moment(this.ytdRange[0]), 'days') /
				  currentYearSalesDocDateQty
				: 0;

		this.year1Result = {
			year: this.selectedComparedYear,
			salesRanking: year1SalesRanking,
			salesMargin: year1SalesMargin,
			revenueContribution: year1RevenueContribution,
			grossProfitContribution: year1GrossProfitContribution,
			caseContribution: year1CaseContribution,
			salesAmount: year1SalesAmount,
			salesDocQty: year1SalesDocQty,
			averageSalesPerCase: year1AverageSalesPerCase,
			averageSalesCase: year1AverageSalesCase,
			averageSales: year1AverageSales,
			averageSalesCycle: year1AverageSalesCycle,
		};

		this.year2Result = {
			year: year2,
			salesRanking: year2SalesRanking,
			salesMargin: year2SalesMargin,
			revenueContribution: year2RevenueContribution,
			grossProfitContribution: year2GrossProfitContribution,
			caseContribution: year2CaseContribution,
			salesAmount: year2SalesAmount,
			salesDocQty: year2SalesDocQty,
			averageSalesPerCase: year2AverageSalesPerCase,
			averageSalesCase: year2AverageSalesCase,
			averageSales: year2AverageSales,
			averageSalesCycle: year2AverageSalesCycle,
		};

		this.year3Result = {
			year: year3,
			salesRanking: year3SalesRanking,
			salesMargin: year3SalesMargin,
			revenueContribution: year3RevenueContribution,
			grossProfitContribution: year3GrossProfitContribution,
			caseContribution: year3CaseContribution,
			salesAmount: year3SalesAmount,
			salesDocQty: year3SalesDocQty,
			averageSalesPerCase: year3AverageSalesPerCase,
			averageSalesCase: year3AverageSalesCase,
			averageSales: year3AverageSales,
			averageSalesCycle: year3AverageSalesCycle,
		};

		this.year4Result = {
			year: year4,
			salesRanking: currentYearSalesRanking,
			salesMargin: currentYearSalesMargin,
			revenueContribution: currentYearRevenueContribution,
			grossProfitContribution: currentYearGrossProfitContribution,
			caseContribution: currentYearCaseContribution,
			salesAmount: currentYearSalesAmount,
			salesDocQty: currentYearSalesDocQty,
			averageSalesPerCase: currentYearAverageSalesPerCase,
			averageSalesCase: currentYearAverageSalesCase,
			averageSales: currentYearAverageSales,
			averageSalesCycle: currentYearAverageSalesCycle,
		};

		this.saveHistory(
			'year1Result',
			'year2Result',
			'year3Result',
			'currentYearResult',
		);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
