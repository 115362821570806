
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment, { Moment } from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import settingx from '@/store/modules/settingx';
import { roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';

@Component({
	components: {
		AmountDisplayerFilterable,
		ShowProfitToggle,
		IncludeCreditNoteToggle,
	},
})
export default class YTDSalesProfitNumberDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public profitPercentage = 0;
	public profitAmount = '' || 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public get showProfit() {
		return settingx.now.showProfit;
	}

	public get title() {
		return this.showProfit ? 'YTD' + ' Sales Profit' : 'YTD' + ' Sales Margin';
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get selectedYearToDateRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('year').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedYearToDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedYearToDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const YTDThisYear = this.selectedYearToDateRange;

		const salesRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.dateRange(YTDThisYear);

		const cnRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.dateRange(YTDThisYear);

		const salesHead = await salesRef.get();
		const salesProfit = await salesHead.getSum('profit');
		const salesAmount = await salesHead.getSum('amount');

		const cNHead = await cnRef.get();
		const cNProfit = await cNHead.getSum('profit');

		this.profitAmount = salesProfit - (this.includeCreditNote ? cNProfit : 0);

		this.profitPercentage = roundToFixed(
			salesAmount !== 0 ? (this.profitAmount / salesAmount) * 100 : 0,
			this.decimal,
		);

		this.saveHistory('profitAmount', 'profitPercentage');
	}
}
