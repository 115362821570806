
import {Component} from 'vue-property-decorator';
import * as echarts from 'echarts';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import moment from 'moment';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {CustomerSupplierTradeCard, PermissionsGroup, ValueText} from '@/store/models.def';
import {GobiColor} from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import Button from 'primevue/button';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		SecondaryLineBarChartFilterable,
		SelectFilter,
		DataInfo,
		Button,
		IncludeCreditNoteToggle,
		IncludeDebitNoteToggle,
	},
})
export default class SalesTrendBarLineChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | echarts.ECharts | any = [];

	public selectedColumn: string = this.columnList[0].value;
	public selectedLine: string = this.lineList[0].value;
	public selectedComparedYear: number = this.compareYearList[0];
  public selectedTimeframe: string = this.timeframe[0];
	public isConfirmApply: number = 0;
  public yearEndTotal: string = '';
  public comparedYearEndTotal: string = '';

	public columnDatas: number[] = [];
	public comparedColumnDatas: number[] = [];
	public accumulatedLineResults: number[] = [];
	public accumulatedComparedLineResults: number[] = [];

	public applyFilter() {
		this.isConfirmApply += 1;
	}

	public get yAxisLabel2() {
		let label: string = '';

		if (this.selectedLine === 'margin') {
			label = '%';
		}

		if (
			this.selectedLine === 'sales' ||
			this.selectedLine === 'profit' ||
			this.selectedLine === 'outstanding'
		) {
			label = gdbx.currencySymbol;
		}
		return label;
	}

	public get compareYearList() {
		const currentYear = moment(this.selectedAsOfDate).year();

		const list: number[] = [];

		for (let i = 1; i < 30; i++) {
			const year = currentYear - i;
			list.push(year);
		}

		return list;
	}

  public get timeframe() {
      return [
        'Monthly',
        '3 months',
        '6 months',
    ];
  }

	public get columnList(): ValueText[] {
		const list = [
			{ text: 'Sales Amt(All Customers)', value: 'allSalesAmount' },
			{ text: 'Sales Amt(New Customers)', value: 'newSalesAmount' },
			{ text: 'Sales Amt(Existing Customers)', value: 'existingSalesAmount' },
			{ text: 'Sales Profit(All Customers)', value: 'allProfitAmount' },
			{ text: 'Sales Profit(New Customers)', value: 'newProfitAmount' },
			{ text: 'Sales Profit(Existing Customers)', value: 'existingProfitAmount' },
      { text: 'Credit Note Amt(All Customers)', value: 'allCreditNote' },
			{ text: 'Number of New Customers', value: 'newCustomer' },
		];

		return list;
	}

	public get lineList(): ValueText[] {
		const list = [
			{ text: 'Margin', value: 'margin' },
			{ text: 'Sales RT', value: 'sales' },
			{ text: 'Credit Note RT', value: 'creditNote' },
			{ text: 'Sales Profit RT', value: 'profit' },
			{ text: 'New Customer', value: 'newCustomerCount' },
		];

		return list;
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currentMonth() {
		return moment(this.selectedAsOfDate).month();
	}

	public get compareYearRange(): [number, number] {
		const diff =
			moment(this.selectedAsOfDate).year() - this.selectedComparedYear;
		return [
			moment(this.selectedDateRange[0])
				.add(-diff, 'year')
				.valueOf(),
			moment(this.selectedDateRange[1])
          .add(-diff, 'year')
          .valueOf(),
		];
	}

	public get yAxisLabel() {
		let label = '';

		if (this.selectedColumn !== 'newCustomer') {
			label = gdbx.currencySymbol;
		}

		return label;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get columnName() {
		const found = this.columnList.find(
			(vt) => vt.value === this.selectedColumn,
		);
		let result = '';
		if (found) {
			result = found.text;
		}
		return result;
	}

	public get lineName() {
		const found = this.lineList.find((vt) => vt.value === this.selectedLine);
		let result = '';
		if (found) {
			result = found.text;
		}
		return result;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.salesTracker;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public thousandFormatter(x) {
    return Math.ceil(x).toLocaleString('en-US');
  }

	public get expensiveHook() {
		const {
			isConfirmApply,
			currentYear,
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
			includeCreditNote,
      includeDebitNote,
		} = this;
		return JSON.stringify([
			isConfirmApply,
			currentYear,
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
			includeCreditNote,
      includeDebitNote,
		]);
	}

	public async expensiveCalc() {
		const currentMonthIndex = this.currentMonth + 1;

		const globalRef = FilteredDatabase.ref('globalCustomers')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('firstDeal', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			]);

		const globalCards: CustomerSupplierTradeCard[] = await (
			await globalRef.get()
		).getCards();

		const newResult = {};

		for (const global of globalCards) {
			const date = moment(global.firstDeal).month();
			if (!newResult[date]) {
				newResult[date] = [];
			}
			newResult[date].push(global.code);
		}

		const comparedYearGlobalRef = FilteredDatabase.ref('globalCustomers')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('firstDeal', [
				['>=', this.compareYearRange[0]],
				['<=', this.compareYearRange[1]],
			]);

		const comparedYearGlobalCards: CustomerSupplierTradeCard[] = await (
			await comparedYearGlobalRef.get()
		).getCards();

		const newComparedYearResult = {};

		for (const global of comparedYearGlobalCards) {
			const date = moment(global.firstDeal).month();
			if (!newComparedYearResult[date]) {
				newComparedYearResult[date] = [];
			}
			newComparedYearResult[date].push(global.code);
		}

		const newCustomerList = [...(await (await globalRef.get()).getSet('code'))];
		const comparedYearNewCustomerList = [
			...(await (await comparedYearGlobalRef.get()).getSet('code')),
		];

		// Report date ref

		const tradeIvDnRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'CS']);

		const tradeCNRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange);

  const tradeDNRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .customers(this.selectedCustomers)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN']);

		const salesRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange);

		const cNRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.selectedDateRange);

		const newTradeIvDnRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(newCustomerList)
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'CS']);

		const newTradeCNRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(newCustomerList)
			.dateRange(this.selectedDateRange);

  const newTradeDNRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .customers(newCustomerList)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN']);

		const newSalesRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(newCustomerList)
			.dateRange(this.selectedDateRange);

		const newCNRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(newCustomerList)
			.dateRange(this.selectedDateRange);

		// Compared year ref

		const tradeIVDnComparedYearRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.compareYearRange)
			.includes('docType', ['IV', 'CS']);

		const tradeCNComparedYearRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.compareYearRange);

  const tradeDNComparedYearRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .customers(this.selectedCustomers)
        .dateRange(this.compareYearRange)
        .includes('docType', ['DN']);

		const salesComparedYearRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.compareYearRange);

		const cNComparedYearRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateRange(this.compareYearRange);

		const newSalesComparedYearRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(comparedYearNewCustomerList)
			.dateRange(this.compareYearRange);

		const newCNComparedYearRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(comparedYearNewCustomerList)
			.dateRange(this.compareYearRange);

		const newTradeIvDnComparedYearRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.customers(comparedYearNewCustomerList)
			.dateRange(this.compareYearRange)
			.includes('docType', ['IV', 'CS']);

		const newTradeCNComparedYearRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.customers(comparedYearNewCustomerList)
			.dateRange(this.compareYearRange);

  const newTradeDNComparedYearRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .customers(comparedYearNewCustomerList)
        .dateRange(this.compareYearRange)
        .includes('docType', ['DN']);

		// report date dimension data

		let pa = 0.05;
		let pb = 0;

		const salesDD = await this._loadDimensionByPeriod(
			salesRef,
			'month',
			pa,
			pb,
			'amount',
		);

		// pb += pa;
		// pa = 0.05;

		const cNDD = await this._loadDimensionByPeriod(
			cNRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

		const tradeIvDnDD = await this._loadDimensionByPeriod(
			tradeIvDnRef,
			'month',
			pa,
			pb,
			'amount',
		);

		// pb += pa;
		// pa = 0.05;

		const tradeCNDD = await this._loadDimensionByPeriod(
			tradeCNRef,
			'month',
			pa,
			pb,
			'amount',
		);

		// pb += pa;
		// pa = 0.05;

  const tradeDNDD = await this._loadDimensionByPeriod(
        tradeDNRef,
        'month',
        pa,
        pb,
        'amount',
    );

  // pb += pa;
  // pa = 0.05;

		const newTradeIvDnDD = await this._loadDimensionByPeriod(
			newTradeIvDnRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

		const newTradeCNDD = await this._loadDimensionByPeriod(
			newTradeCNRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

  const newTradeDNDD = await this._loadDimensionByPeriod(
        newTradeDNRef,
        'month',
        pa,
        pb,
        'amount',
    );

  pb += pa;
  pa = 0.05;

		const profitDD = await this._loadDimensionByPeriod(
			salesRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.05;

		const profitCNDD = await this._loadDimensionByPeriod(
			cNRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.05;

		const newSalesProfitDD = await this._loadDimensionByPeriod(
			newSalesRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.05;

		const newCNProfitDD = await this._loadDimensionByPeriod(
			newCNRef,
			'month',
			pa,
			pb,
			'profit',
		);

		// compared year dimension data

		pb += pa;
		pa = 0.05;

		const salesComparedYearDD = await this._loadDimensionByPeriod(
			salesComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

		const CNComparedYearDD = await this._loadDimensionByPeriod(
			cNComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

		const tradeIvDnComparedYearDD = await this._loadDimensionByPeriod(
			tradeIVDnComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

		const tradeCNComparedYearDD = await this._loadDimensionByPeriod(
			tradeCNComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

  const tradeDNComparedYearDD = await this._loadDimensionByPeriod(
        tradeDNComparedYearRef,
        'month',
        pa,
        pb,
        'amount',
    );

  pb += pa;
  pa = 0.05;

		const newTradeIvDnComparedYearDD = await this._loadDimensionByPeriod(
			newTradeIvDnComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

		const newTradeCNComparedYearDD = await this._loadDimensionByPeriod(
			newTradeCNComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.05;

  const newTradeDNComparedYearDD = await this._loadDimensionByPeriod(
        newTradeDNComparedYearRef,
        'month',
        pa,
        pb,
        'amount',
    );

  pb += pa;
  pa = 0.05;

		const profitComparedYearDD = await this._loadDimensionByPeriod(
			salesComparedYearRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.05;

		const profitCNComparedYearDD = await this._loadDimensionByPeriod(
			cNComparedYearRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.05;

		const newProfitComparedYearDD = await this._loadDimensionByPeriod(
			newSalesComparedYearRef,
			'month',
			pa,
			pb,
			'profit',
		);

		pb += pa;
		pa = 0.05;

		const newCNProfitComparedYearDD = await this._loadDimensionByPeriod(
			newCNComparedYearRef,
			'month',
			pa,
			pb,
			'profit',
		);

		this.generateLoadingText(1);

		const lineDatas: number[] = [];
		const comparedLineDatas: number[] = [];

		const accumulatedProfitDatas: number[] = [];
		const accumulatedSalesDatas: number[] = [];
		const accumulatedComparedProfitDatas: number[] = [];
		const accumulatedComparedSalesDatas: number[] = [];

		this.columnDatas = [];
		this.comparedColumnDatas = [];
  this.xAxisData = [];

		switch (this.selectedColumn) {
			case 'allSalesAmount':
        this.columnDatas = tradeIvDnDD.reverse().map(
            (dd, index) =>
                dd.sum - (this.includeCreditNote ? tradeCNDD[index].sum : 0)
                + (this.includeDebitNote ? tradeDNDD[index].sum : 0),
        );
        this.comparedColumnDatas = tradeIvDnComparedYearDD.reverse().map(
            (dd, index) =>
                dd.sum -
                (this.includeCreditNote ? tradeCNComparedYearDD[index].sum : 0)
                + (this.includeDebitNote ? tradeDNComparedYearDD[index].sum : 0),
        );

        if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0] + this.columnDatas[1] + this.columnDatas[2]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[4] + this.columnDatas[5]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[7] + this.columnDatas[8]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[10] + this.columnDatas[11]),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0] + this.comparedColumnDatas[1] + this.comparedColumnDatas[2]),
          );

          newArray2.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[4] + this.comparedColumnDatas[5]),
          );

          newArray2.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[7] + this.comparedColumnDatas[8]),
          );

          newArray2.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[10] + this.comparedColumnDatas[11]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${tradeIvDnDD[0].text} - ${tradeIvDnDD[2].text}`,
            `${tradeIvDnDD[3].text} - ${tradeIvDnDD[5].text}`,
            `${tradeIvDnDD[6].text} - ${tradeIvDnDD[8].text}`,
            `${tradeIvDnDD[9].text} - ${tradeIvDnDD[11].text}`,
          ];

        }  else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0]
                  + this.columnDatas[1]
                  + this.columnDatas[2]
                  + this.columnDatas[3]
                  + this.columnDatas[4]
                  + this.columnDatas[5]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[7]
                  + this.columnDatas[8]
                  + this.columnDatas[9]
                  + this.columnDatas[10]
                  + this.columnDatas[11]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0]
                  + this.comparedColumnDatas[1]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[5]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[7]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[11]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
                `${tradeIvDnDD[0].text} - ${tradeIvDnDD[5].text}`,
                `${tradeIvDnDD[6].text} - ${tradeIvDnDD[11].text}`,
            ];

        }
				    break;
			case 'allCreditNote':

        this.columnDatas = tradeCNDD.reverse().map((dd) => dd.sum);
        this.comparedColumnDatas = tradeCNComparedYearDD.map((dd) => dd.sum);

        if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0] + this.columnDatas[1] + this.columnDatas[2]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[4] + this.columnDatas[5]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[7] + this.columnDatas[8]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[10] + this.columnDatas[11]),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0] + this.comparedColumnDatas[1] + this.comparedColumnDatas[2]),
          );

          newArray2.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[4] + this.comparedColumnDatas[5]),
          );

          newArray2.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[7] + this.comparedColumnDatas[8]),
          );

          newArray2.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[10] + this.comparedColumnDatas[11]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${tradeCNDD[0].text} - ${tradeCNDD[2].text}`,
            `${tradeCNDD[3].text} - ${tradeCNDD[5].text}`,
            `${tradeCNDD[6].text} - ${tradeCNDD[8].text}`,
            `${tradeCNDD[9].text} - ${tradeCNDD[11].text}`,
          ];

        }  else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0]
                  + this.columnDatas[1]
                  + this.columnDatas[2]
                  + this.columnDatas[3]
                  + this.columnDatas[4]
                  + this.columnDatas[5]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[7]
                  + this.columnDatas[8]
                  + this.columnDatas[9]
                  + this.columnDatas[10]
                  + this.columnDatas[11]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0]
                  + this.comparedColumnDatas[1]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[5]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[7]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[11]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${tradeCNDD[0].text} - ${tradeCNDD[5].text}`,
            `${tradeCNDD[6].text} - ${tradeCNDD[11].text}`,
          ];

        }
				    break;
			case 'newSalesAmount':

          this.columnDatas = newTradeIvDnDD.reverse().map(
              (dd, index) =>
                  dd.sum - (this.includeCreditNote ? newTradeCNDD[index].sum : 0)
                  + (this.includeDebitNote ? newTradeDNDD[index].sum : 0),
          );
          this.comparedColumnDatas = newTradeIvDnComparedYearDD.map(
              (dd, index) => dd.sum - newTradeCNComparedYearDD[index].sum,
          );

          if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0] + this.columnDatas[1] + this.columnDatas[2]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[4] + this.columnDatas[5]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[7] + this.columnDatas[8]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[10] + this.columnDatas[11]),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0] + this.comparedColumnDatas[1] + this.comparedColumnDatas[2]),
          );

          newArray2.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[4] + this.comparedColumnDatas[5]),
          );

          newArray2.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[7] + this.comparedColumnDatas[8]),
          );

          newArray2.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[10] + this.comparedColumnDatas[11]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${newTradeIvDnDD[0].text} - ${newTradeIvDnDD[2].text}`,
            `${newTradeIvDnDD[3].text} - ${newTradeIvDnDD[5].text}`,
            `${newTradeIvDnDD[6].text} - ${newTradeIvDnDD[8].text}`,
            `${newTradeIvDnDD[9].text} - ${newTradeIvDnDD[11].text}`,
          ];

        }  else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0]
                  + this.columnDatas[1]
                  + this.columnDatas[2]
                  + this.columnDatas[3]
                  + this.columnDatas[4]
                  + this.columnDatas[5]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[7]
                  + this.columnDatas[8]
                  + this.columnDatas[9]
                  + this.columnDatas[10]
                  + this.columnDatas[11]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0]
                  + this.comparedColumnDatas[1]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[5]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[7]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[11]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${newTradeIvDnDD[0].text} - ${newTradeIvDnDD[5].text}`,
            `${newTradeIvDnDD[6].text} - ${newTradeIvDnDD[11].text}`,
          ];

        }
				      break;
			case 'existingSalesAmount':
				const salesDatas = tradeIvDnDD.reverse().map(
					(dd, index) =>
						dd.sum - (this.includeCreditNote ? tradeCNDD[index].sum : 0)
              + (this.includeDebitNote ? tradeDNDD[index].sum : 0),
				);
				const newSalesDatas = newTradeIvDnDD.reverse().map(
					(dd, index) =>
						dd.sum - (this.includeCreditNote ? newTradeCNDD[index].sum : 0)
              + (this.includeDebitNote ? newTradeDNDD[index].sum : 0),
				);
				const salesComparedYearDatas = tradeIvDnComparedYearDD.reverse().map(
					(dd, index) =>
						dd.sum -
						(this.includeCreditNote ? tradeCNComparedYearDD[index].sum : 0)
              + (this.includeDebitNote ? tradeDNComparedYearDD[index].sum : 0),
				);
				const newSalesComparedYearDatas = newTradeIvDnComparedYearDD.reverse().map(
					(dd, index) =>
						dd.sum -
						(this.includeCreditNote ? newTradeCNComparedYearDD[index].sum : 0)
              + (this.includeDebitNote ? newTradeDNComparedYearDD[index].sum : 0),
				);
				this.columnDatas = salesDatas.map(
					(item, index) => item - newSalesDatas[index],
				);
				this.comparedColumnDatas = salesComparedYearDatas.map(
					(item, index) => item - newSalesComparedYearDatas[index],
				);
    if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0] + this.columnDatas[1] + this.columnDatas[2]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[4] + this.columnDatas[5]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[7] + this.columnDatas[8]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[10] + this.columnDatas[11]),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0] + this.comparedColumnDatas[1] + this.comparedColumnDatas[2]),
          );

          newArray2.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[4] + this.comparedColumnDatas[5]),
          );

          newArray2.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[7] + this.comparedColumnDatas[8]),
          );

          newArray2.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[10] + this.comparedColumnDatas[11]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${tradeIvDnDD[0].text} - ${tradeIvDnDD[2].text}`,
            `${tradeIvDnDD[3].text} - ${tradeIvDnDD[5].text}`,
            `${tradeIvDnDD[6].text} - ${tradeIvDnDD[8].text}`,
            `${tradeIvDnDD[9].text} - ${tradeIvDnDD[11].text}`,
          ];

        }  else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0]
                  + this.columnDatas[1]
                  + this.columnDatas[2]
                  + this.columnDatas[3]
                  + this.columnDatas[4]
                  + this.columnDatas[5]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[7]
                  + this.columnDatas[8]
                  + this.columnDatas[9]
                  + this.columnDatas[10]
                  + this.columnDatas[11]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0]
                  + this.comparedColumnDatas[1]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[5]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[7]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[11]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${tradeIvDnDD[0].text} - ${tradeIvDnDD[5].text}`,
            `${tradeIvDnDD[6].text} - ${tradeIvDnDD[11].text}`,
          ];

        }
				break;
			case 'allProfitAmount':

        this.columnDatas = profitDD.reverse().map(
            (dd, index) =>
                dd.sum - (this.includeCreditNote ? profitCNDD[index].sum : 0),
        );
        this.comparedColumnDatas = profitComparedYearDD.map(
            (dd, index) =>
                dd.sum -
                (this.includeCreditNote ? profitCNComparedYearDD[index].sum : 0),
        );

        if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0] + this.columnDatas[1] + this.columnDatas[2]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[4] + this.columnDatas[5]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[7] + this.columnDatas[8]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[10] + this.columnDatas[11]),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0] + this.comparedColumnDatas[1] + this.comparedColumnDatas[2]),
          );

          newArray2.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[4] + this.comparedColumnDatas[5]),
          );

          newArray2.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[7] + this.comparedColumnDatas[8]),
          );

          newArray2.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[10] + this.comparedColumnDatas[11]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${profitDD[0].text} - ${profitDD[2].text}`,
            `${profitDD[3].text} - ${profitDD[5].text}`,
            `${profitDD[6].text} - ${profitDD[8].text}`,
            `${profitDD[9].text} - ${profitDD[11].text}`,
          ];

        }  else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0]
                  + this.columnDatas[1]
                  + this.columnDatas[2]
                  + this.columnDatas[3]
                  + this.columnDatas[4]
                  + this.columnDatas[5]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[7]
                  + this.columnDatas[8]
                  + this.columnDatas[9]
                  + this.columnDatas[10]
                  + this.columnDatas[11]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0]
                  + this.comparedColumnDatas[1]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[5]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[7]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[11]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${profitDD[0].text} - ${profitDD[5].text}`,
            `${profitDD[6].text} - ${profitDD[11].text}`,
          ];

        }
				    break;
			case 'newProfitAmount':

        this.columnDatas = newSalesProfitDD.reverse().map(
            (dd, index) =>
                dd.sum - (this.includeCreditNote ? newCNProfitDD[index].sum : 0),
        );
        this.comparedColumnDatas = newProfitComparedYearDD.map(
            (dd, index) =>
                dd.sum -
                (this.includeCreditNote ? newCNProfitComparedYearDD[index].sum : 0),
        );

        if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0] + this.columnDatas[1] + this.columnDatas[2]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[4] + this.columnDatas[5]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[7] + this.columnDatas[8]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[10] + this.columnDatas[11]),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0] + this.comparedColumnDatas[1] + this.comparedColumnDatas[2]),
          );

          newArray2.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[4] + this.comparedColumnDatas[5]),
          );

          newArray2.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[7] + this.comparedColumnDatas[8]),
          );

          newArray2.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[10] + this.comparedColumnDatas[11]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${newSalesProfitDD[0].text} - ${newSalesProfitDD[2].text}`,
            `${newSalesProfitDD[3].text} - ${newSalesProfitDD[5].text}`,
            `${newSalesProfitDD[6].text} - ${newSalesProfitDD[8].text}`,
            `${newSalesProfitDD[9].text} - ${newSalesProfitDD[11].text}`,
          ];

        }  else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[0]
                  + this.columnDatas[1]
                  + this.columnDatas[2]
                  + this.columnDatas[3]
                  + this.columnDatas[4]
                  + this.columnDatas[5]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[7]
                  + this.columnDatas[8]
                  + this.columnDatas[9]
                  + this.columnDatas[10]
                  + this.columnDatas[11]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[0]
                  + this.comparedColumnDatas[1]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[5]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[7]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[11]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

          this.xAxisData = [
            `${newSalesProfitDD[0].text} - ${newSalesProfitDD[5].text}`,
            `${newSalesProfitDD[6].text} - ${newSalesProfitDD[11].text}`,
          ];

        }
				    break;
			case 'existingProfitAmount':
				const profitDatas = profitDD.reverse().map(
					(dd, index) =>
						dd.sum - (this.includeCreditNote ? profitCNDD[index].sum : 0),
				);
				const newProfitDatas = newSalesProfitDD.reverse().map(
					(dd, index) =>
						dd.sum - (this.includeCreditNote ? newCNProfitDD[index].sum : 0),
				);

				const profitComparedYearDatas = profitComparedYearDD.map(
					(dd, index) =>
						dd.sum -
						(this.includeCreditNote ? profitCNComparedYearDD[index].sum : 0),
				);
				const newProfitComparedYearDatas = newProfitComparedYearDD.map(
					(dd, index) =>
						dd.sum -
						(this.includeCreditNote ? newCNProfitComparedYearDD[index].sum : 0),
				);
				this.columnDatas = profitDatas.map(
					(item, index) => item - newProfitDatas[index],
				);
				this.comparedColumnDatas = profitComparedYearDatas.map(
					(item, index) => item - newProfitComparedYearDatas[index],
				);
    if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[12] + this.columnDatas[11] + this.columnDatas[10]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[8] + this.columnDatas[7]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[5] + this.columnDatas[4]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[2] + this.columnDatas[1]),
          );

          // comparedYear array

          newArray.push(
              (this.comparedColumnDatas[12] + this.comparedColumnDatas[11] + this.comparedColumnDatas[10]),
          );

          newArray.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[8] + this.comparedColumnDatas[7]),
          );

          newArray.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[5] + this.comparedColumnDatas[4]),
          );

          newArray.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[2] + this.comparedColumnDatas[1]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;
        } else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[12]
                  + this.columnDatas[11]
                  + this.columnDatas[10]
                  + this.columnDatas[9]
                  + this.columnDatas[8]
                  + this.columnDatas[7]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[5]
                  + this.columnDatas[4]
                  + this.columnDatas[3]
                  + this.columnDatas[2]
                  + this.columnDatas[1]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[12]
                  + this.comparedColumnDatas[11]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[7]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[5]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[1]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

        }
				break;
			case 'newCustomer':
				const newKeys: string[] = Object.keys(newResult);

				const newValues: string[][] = Object.values(newResult);

				const newComparedYearValues: string[][] = Object.values(
					newComparedYearResult,
				);
				newKeys.forEach((item1, index) => {
					this.columnDatas.push(
						newValues.length > 0 ? newValues[index].length : 0,
					);
				});

				newComparedYearValues.forEach((item2, index) => {
					this.comparedColumnDatas.push(
						newComparedYearValues.length > 0
							? newComparedYearValues[index].length
							: 0,
					);
				});

    if (this.selectedTimeframe === '3 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[12] + this.columnDatas[11] + this.columnDatas[10]),
          );

          newArray.push(
              (this.columnDatas[9] + this.columnDatas[8] + this.columnDatas[7]),
          );

          newArray.push(
              (this.columnDatas[6] + this.columnDatas[5] + this.columnDatas[4]),
          );

          newArray.push(
              (this.columnDatas[3] + this.columnDatas[2] + this.columnDatas[1]),
          );

          // comparedYear array

          newArray.push(
              (this.comparedColumnDatas[12] + this.comparedColumnDatas[11] + this.comparedColumnDatas[10]),
          );

          newArray.push(
              (this.comparedColumnDatas[9] + this.comparedColumnDatas[8] + this.comparedColumnDatas[7]),
          );

          newArray.push(
              (this.comparedColumnDatas[6] + this.comparedColumnDatas[5] + this.comparedColumnDatas[4]),
          );

          newArray.push(
              (this.comparedColumnDatas[3] + this.comparedColumnDatas[2] + this.comparedColumnDatas[1]),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;
        } else if (this.selectedTimeframe === '6 months') {
          const newArray: any [] = [];
          const newArray2: any [] = [];

          // currentYear array

          newArray.push(
              (this.columnDatas[12]
                  + this.columnDatas[11]
                  + this.columnDatas[10]
                  + this.columnDatas[9]
                  + this.columnDatas[8]
                  + this.columnDatas[7]
              ),
          );

          newArray.push(
              (this.columnDatas[6]
                  + this.columnDatas[5]
                  + this.columnDatas[4]
                  + this.columnDatas[3]
                  + this.columnDatas[2]
                  + this.columnDatas[1]
              ),
          );

          // comparedYear array

          newArray2.push(
              (this.comparedColumnDatas[12]
                  + this.comparedColumnDatas[11]
                  + this.comparedColumnDatas[10]
                  + this.comparedColumnDatas[9]
                  + this.comparedColumnDatas[8]
                  + this.comparedColumnDatas[7]
              ),
          );

          newArray2.push(
              (this.comparedColumnDatas[6]
                  + this.comparedColumnDatas[5]
                  + this.comparedColumnDatas[4]
                  + this.comparedColumnDatas[3]
                  + this.comparedColumnDatas[2]
                  + this.comparedColumnDatas[1]
              ),
          );

          this.columnDatas = newArray;
          this.comparedColumnDatas = newArray2;

        }
		}

		this.accumulatedLineResults = [];
		this.accumulatedComparedLineResults = [];

		const yearDatas: echarts.ECharts | any = {
			name: this.columnName + ' ' + this.currentYear,
			type: 'bar',
			barWidth: '30%',
			itemStyle: { color: GobiColor.COLOR1 },
			data: this.columnDatas,
		};

		const comparedYearDatas: echarts.ECharts | any = {
			name: this.columnName + ' ' + this.selectedComparedYear,
			type: 'bar',
			itemStyle: { color: GobiColor.COLOR3 },
			barWidth: '30%',
			data: this.comparedColumnDatas,
		};

		const line: echarts.ECharts | any = {
			name: this.lineName + ' ' + this.currentYear,
			type: 'line',
			itemStyle: { color: GobiColor.COLOR5 },
			yAxisIndex: 1,
			data: this.accumulatedLineResults,
		};

		const comparedLine: echarts.ECharts | any = {
			name: this.lineName + ' ' + this.selectedComparedYear,
			type: 'line',
			itemStyle: { color: GobiColor.COLOR6 },
			yAxisIndex: 1,
			data: this.accumulatedComparedLineResults,
		};

		if (this.selectedTimeframe === 'Monthly') {
      this.xAxisData = salesDD.reverse().map((dd) => dd.text);
    }

		this.series = [];
		if (this.selectedComparedYear !== null) {
			this.series = [yearDatas, comparedYearDatas];
		} else {
			this.series = [yearDatas, comparedYearDatas];
		}

  this.yearEndTotal = gdbx.currencySymbol + ' '
        + this.thousandFormatter(this.columnDatas.reduce((a, b) => a + b, 0));

  this.comparedYearEndTotal = gdbx.currencySymbol + ' '
        + this.thousandFormatter(this.comparedColumnDatas.reduce((a, b) => a + b, 0));

		this.saveHistory('series', 'xAxisData', 'yearEndTotal', 'comparedYearEndTotal');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
