
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import MonthRevenueContributionNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/MonthRevenueContributionNumberDisplayer.vue';
import YTDSalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/YTDSalesNumberDisplayer.vue';
import YTDSalesProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/YTDSalesProfitNumberDisplayer.vue';
import YTDRevenueContributionNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/YTDRevenueContributionNumberDisplayer.vue';
import MonthSalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/MonthSalesNumberDisplayer.vue';
import MonthSalesProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/MonthSalesProfitNumberDisplayer.vue';
import AgentSalesAnalysisTreeMap from '@/components/reports-v2/components/codedWidgets/sales/AgentSalesAnalysisTreeMap.vue';
import IndividualAgentSalesMetricTable from '@/components/reports-v2/components/codedWidgets/sales/IndividualAgentSalesMetricTable.vue';
import SalesTrendBarLineChart from '@/components/reports-v2/components/codedWidgets/sales/SalesTrendBarLineChart.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { ValueText } from '@/store/models.def';
import Message from 'primevue/message';
import Divider from 'primevue/divider';

@Component({
	components: {
		MonthSalesNumberDisplayer,
		MonthRevenueContributionNumberDisplayer,
		YTDSalesNumberDisplayer,
		YTDSalesProfitNumberDisplayer,
		YTDRevenueContributionNumberDisplayer,
		MonthSalesProfitNumberDisplayer,
		AgentSalesAnalysisTreeMap,
		IndividualAgentSalesMetricTable,
		StickyFilterContainer,
		DateFilter,
		LastSync,
		SelectFilter,
		SalesTrendBarLineChart,
		Message,
		Divider,
	},
})
export default class SalesIndividualAgent extends Vue {
	public selectedDay: number = moment().endOf('month').endOf('day').valueOf();
	public defaultAgent: string | null = null;
	public servingYear: number = 0;
	public customerCount: number = 0;

	@Watch('selectedAgents', { immediate: true })
	public onSelectedAgentsChange(newVal: Array<ValueText<string>>) {
		if (newVal[0].value) {
			this.defaultAgent = newVal[0].value;
		}
	}

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public receivedCustomerCount(value: number) {
		this.customerCount = value;
	}

	public receivedServingYear(value: number) {
		this.servingYear = value;
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('month').valueOf();
	}

	public get selectedCurrentMonthRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('month').valueOf(),
			moment(this.selectedDay).endOf('month').valueOf(),
		];
	}

	public get selectedDateRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

	public get selectedYearToDateRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('month').valueOf(),
		];
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public get selectedCustomers() {
		return gdbx.allCustomers.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
