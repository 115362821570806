
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		AmountDisplayerFilterable,
		IncludeCreditNoteToggle,
		IncludeDebitNoteToggle,
	},
})
export default class MonthSalesNumberDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public salesCount = 0;
	public salesAmount = '' || 0;
  public activeCustomersCount = 0;
  public invoiceCount = 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

  public get includeDebitNote() {
		return settingx.now.includeDebitNote;
	}

    public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			includeCreditNote,
      includeDebitNote,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			includeCreditNote,
      includeDebitNote,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.includes('docType', ['IV', 'CS']);

        const invoiceRef = FilteredDatabase.ref('sales')
            .agents(this.selectedAgents)
            .dateRange(this.selectedDateRange)
            .includes('docType', ['IV']);

		const CNRef = FilteredDatabase.ref('creditNotes')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange);

  const DNRef = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.includes('docType', ['DN']);

		const salesAmount = await (await tradeRef.get()).getSum('amount');
		const CNAmount = await (await CNRef.get()).getSum('amount');
        const DNAmount = await (await DNRef.get()).getSum('amount');

        const invoiceCards = await (await invoiceRef.get()).getCards();
        this.invoiceCount = [...new Set(invoiceCards.map((invoice) => invoice.docNo))].length;

		this.salesAmount = salesAmount - (this.includeCreditNote ? CNAmount : 0) + (this.includeDebitNote ? DNAmount : 0);

  this.activeCustomersCount = [
      ...(await (await tradeRef.get()).getSet('code')),
    ].length;

		this.saveHistory('salesAmount');
	}
}
